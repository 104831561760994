import React, { useState, useEffect } from "react";
import useStyles from "../styles/login";
import { Typography, Container, Button, Drawer } from "@material-ui/core";
import { TFunction } from "next-i18next";
import { withTranslation } from "../i18n";
import { useRouter } from "next/router";
import { loginWithGoogle } from "../src/config/firebase";
import {
  checkAvailability,
  loginWithFirebaseToken,
} from "../src/services/rooms";
import Loading from "../src/components/Loading";
import { Email, KeyboardArrowDownOutlined } from "@material-ui/icons";
import PhoneCodePicker from "../src/components/PhoneCodePicker";
import ReusableDialog from "../src/components/ReusableDialog";
import Navbar from "../src/components/Navbar";
import dayjs from "dayjs";
import dataCountry from "../public/data-country";

type Props = {
  readonly t: TFunction;
};

const Login = (props: Props) => {
  const classes = useStyles(props);
  const { t } = props;
  const router = useRouter();
  const redirect = router.query.redirect;
  const [isLoading, setIsLoading] = useState(false);
  const [openPhoneCodePicker, setOpenPhoneCodePicker] = useState(false);
  const [phoneCode, setPhoneCode] = useState({
    country_code: "ID",
    name: "Indonesia",
    dial_code: "+62",
  });
  const [prevPhoneCode, setPrevPhoneCode] = useState({
    country_code: "ID",
    name: "Indonesia",
    dial_code: "+62",
  });
  const [phone, setPhone] = useState("");
  const [register, setRegister] = useState(false);

  const handleSelectPhoneCode = (data) => {
    setPhoneCode(data);
    setOpenPhoneCodePicker(false);
  };

  const getCurrentDataCountry = (phoneNumber) => {
    const country = dataCountry.find((item) =>
      `+${phoneNumber}`.startsWith(item.dial_code)
    );
    if (country) {
      setPhoneCode(country);
      setPrevPhoneCode(country);
    }
  };

  useEffect(() => {
    const phoneData = localStorage.getItem("phoneData");
    if (phoneData) {
      getCurrentDataCountry(phoneData);
    }
  }, []);

  useEffect(() => {
    const phoneData = localStorage.getItem("phoneData");
    const dialCode = phoneCode.dial_code;
    if (phoneData) setPhone(phoneData.slice(dialCode.length - 1));
  }, [prevPhoneCode]);

  const handleChange = (event) => {
    let pattern = /^$|^[0-9]+$/;
    if (pattern.test(event.target.value)) {
      setPhone(event.target.value);
    } else {
      setPhone(event.target.value.replace(/[^0-9]/g, ""));
    }
  };

  const validateTypePhoneNumber = () => {
    const phoneNumber = phoneCode.dial_code.slice(1) + phone.replace(/^0+/, "");
    return phoneNumber;
  };

  const handleVerify = () => {
    const data = { phoneNumber: validateTypePhoneNumber() };
    setIsLoading(true);
    checkAvailability(data)
      .then((res) => {
        setIsLoading(false);
        setRegister(true);
        localStorage.setItem("phoneData", validateTypePhoneNumber() as string);
      })
      .catch((error) => {
        setIsLoading(false);
        if (redirect) {
          router.push(
            `/verify-method?method=sign-in&verifyMethod=phone&redirect=${redirect}`
          );
        } else {
          router.push(`/verify-method?method=sign-in&verifyMethod=phone`);
        }
        localStorage.setItem("phoneData", validateTypePhoneNumber() as string);
      });
  };

  const handleRegister = () => {
    if (redirect) {
      router.push(`/register?redirect=${redirect}`);
    } else {
      router.push(`/register`);
    }
  };

  const handleLoginWithEmail = () => {
    if (redirect) {
      router.push(
        `/login-with-email?method=sign-in&verifyMethod=email&redirect=${redirect}`
      );
    } else {
      router.push(`/login-with-email?method=sign-in&verifyMethod=email`);
    }
  };

  useEffect(() => {
    const asPath = localStorage.getItem("from");
    if (localStorage.getItem("token")) {
      if (asPath) {
        router.push(`${asPath}`);
        return;
      } else if (router.query.redirect) {
        if (router.query.redirect.includes("javascript:prompt")) {
          router.push("/404");
        } else {
          router.push(window.location.search.slice(10));
        }
      } else {
        router.push("/");
      }
    }
  }, []);

  const handleLoginGoogle = async () => {
    setIsLoading(true);
    const tokenFirebase = await loginWithGoogle();
    if (tokenFirebase !== undefined) {
      const response = await loginWithFirebaseToken(tokenFirebase);
      setIsLoading(false);
      localStorage.setItem("data_login", JSON.stringify(response.data));
      localStorage.setItem("token", response.data.token);
      if (response.meta.status_code === 200) {
        if (router.query.redirect) {
          router.push(window.location.search.slice(10));
          localStorage.setItem(
            "loginDate",
            dayjs(new Date()).format("YYYY-MM-DD")
          );
        } else {
          router.push("/");
          localStorage.setItem(
            "loginDate",
            dayjs(new Date()).format("YYYY-MM-DD")
          );
        }
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Container
        maxWidth="xs"
        className={classes.root}
        style={{ height: "100vh" }}
      >
        <Navbar t={t} title={t("login")} onClickBack={() => router.back()} />
        <div className={classes.wrapper}>
          <div className={classes.logoWrapper}>
            <img
              src={
                process.env.NEXT_PUBLIC_COLOR_LOGO ||
                "https://swaps-bucket.s3.ap-southeast-1.amazonaws.com/Group%2026.svg-2022-08-31T05%3A35%3A49.133Z"
              }
              style={{ height: 40 }}
            />
          </div>
          <div className={classes.textKeterangan}>
            <Typography variant="caption" align="center">
              <b>{t("login")}</b>{" "}
              {t(
                "to-save-your-profile-and-feel-the-experience-explore-Dafam-Hotel-in-your-screen"
              )}
            </Typography>
          </div>

          <div
            className={classes.inputWrapper}
            style={{
              marginBottom: 20,
            }}
          >
            <Typography variant="body2">{t("phone-number")}</Typography>
            <div className={classes.phoneCodeWrapper}>
              <div
                className={classes.phoneCode}
                onClick={() => setOpenPhoneCodePicker(true)}
              >
                <img
                  src={`/img/flags/${phoneCode?.country_code.toLocaleLowerCase()}.png`}
                  height={20}
                  width={30}
                  alt=""
                />
                <Typography style={{ padding: "0px 6px" }}>
                  {phoneCode?.dial_code}
                </Typography>
                <KeyboardArrowDownOutlined />
              </div>
              <input
                placeholder={t("example-phone-number")}
                className={classes.inputPhone}
                onChange={(event) => handleChange(event)}
                value={phone}
              />
            </div>
          </div>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            className={classes.buttonLogin}
            onClick={handleVerify}
            disabled={phone?.length < 6 || !phone?.length ? true : false}
          >
            {t("login")}
          </Button>
          {(process.env.LOGIN_WITH_GOOGLE === "true" ||
            process.env.LOGIN_WITH_EMAIL === "true") && (
            <div style={{ padding: "24px 0" }}>
              <div className={classes.orLine}>
                <Typography className={classes.orText}>{t("or")}</Typography>
              </div>
            </div>
          )}
          {process.env.LOGIN_WITH_GOOGLE === "true" && (
            <Button
              variant="outlined"
              fullWidth
              style={{ fontSize: 14 }}
              onClick={handleLoginGoogle}
            >
              <img src="/img/Google_G_Logo.svg" style={{ marginRight: 15 }} />
              {t("login-with-google")}
            </Button>
          )}
          {process.env.LOGIN_WITH_EMAIL === "true" && (
            <Button
              variant="outlined"
              fullWidth
              style={{ fontSize: 14, marginTop: 12 }}
              onClick={handleLoginWithEmail}
            >
              <Email style={{ marginRight: 15, color: "#A0A4A8" }} />
              {t("login-with-email")}
            </Button>
          )}
          <div
            style={{
              bottom: "1rem",
              textAlign: "center",
              width: "90%",
              position: "absolute",
              maxWidth: "444px",
            }}
          >
            <Typography style={{ fontSize: 14 }}>
              {t("dont-have-account-yet?")}{" "}
              <span
                style={{ color: "#2F9CF1", fontWeight: 600, cursor: "pointer" }}
                onClick={handleRegister}
              >
                {t("register-here")}
              </span>
            </Typography>
          </div>
        </div>
        <Drawer
          anchor="bottom"
          open={openPhoneCodePicker}
          onClose={() => setOpenPhoneCodePicker(false)}
          style={{ margin: "auto", maxWidth: 444, width: "100%" }}
        >
          <div style={{ margin: "auto", maxWidth: 444, width: "100%" }}>
            <PhoneCodePicker
              t={t}
              handleSelectPhoneCode={handleSelectPhoneCode}
              onClose={() => setOpenPhoneCodePicker(false)}
            />
          </div>
        </Drawer>
      </Container>
      <ReusableDialog
        open={register}
        button={t("register")}
        onSubmit={handleRegister}
        onClose={() => setRegister(false)}
        content={
          <div style={{ textAlign: "center" }}>
            <img src="/img/sorry.svg" />
            <Typography style={{ fontSize: "16px", fontWeight: 600 }}>
              {t("havent-registered")}
            </Typography>
            <Typography style={{ fontSize: "12px", color: "#808080" }}>
              {t("lets-register")}
            </Typography>
          </div>
        }
      />
      <Loading t={t} open={isLoading} onClose={() => setIsLoading(false)} />
    </div>
  );
};

Login.getInitialProps = async () => ({
  namespacesRequired: ["common"],
});

export default withTranslation("common")(Login);
