import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 325,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 10,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 2),
    },
    root: {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
      backgroundColor: "#ffffff",
      position: "relative",
    },
    logoWrapper: {
      textAlign: "center",
    },
    wrapper: {
      padding: 20,
      backgroundColor: "#ffffff",
    },
    textKeterangan: {
      marginTop: 24,
      textAlign: "center",
    },
    inputStyled: {
      border: "none",
      fontSize: "0.875rem",
      outline: "none",
      margin: "10px 0px",
      width: "100%",
    },
    inputWrapper: {
      borderBottom: "1px solid rgba(0,0,0,.08)",
      paddingTop: 30,
    },
    buttonLogin: {
      fontWeight: "bold",
      fontSize: 16,
      boxShadow: "0px 10px 15px rgba(51, 131, 225, 0.2)",
      borderRadius: 5,
      ":disabled": {
        boxShadow: "none !important",
      },
    },
    orLine: {
      borderBottom: "1.5px solid #cacccf",
      display: "flex",
      alignItems: "center",
      width: "100%",
      marginBottom: 10,
      justifyContent: "center",
    },
    orText: {
      marginBottom: "-12px !important",
      backgroundColor: "#ffffff",
      padding: "0 20px",
      color: "#cacccf",
      fontSize: 14,
    },
    navbar: {
      display: "flex",
      padding: 20,
      background: "#ffffff",
      width: "100%",
      maxWidth: 444,
      zIndex: 1,
      margin: 0,
    },
    phoneCode: {
      display: "flex",
      width: "fit-content",
      borderRight: "2px solid #e0e4e8",
      cursor: "pointer",
    },
    phoneCodeWrapper: {
      marginTop: 8,
      paddingBottom: 12,
      borderBottom: "1px solid #9E9E9E",
      display: "flex",
    },
    inputPhone: {
      border: "none",
      outline: "none",
      fontSize: "1rem",
      paddingLeft: 8,
      fontFamily: "Lato, sans-serif",
      fontWeight: 400,
      lineHeight: 1.5,
    },
  })
);

export default useStyles;
